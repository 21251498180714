//越南语
export default {
tip:{
fromSuccess:'gửi thành công'
},
login:{
  linkList:[{name:'Star Credits Mall',url:'/'},{name:'Đăng ký với tư cách là một nhà giao dịch',url:'https://tw.fisg.com/register/trader?link_id=ct01egz0&referrer_id=v4w8jplv'}], 
  registered:{
  text:"Không có tài khoản? Bấm Đăng ký >>",
  url:'https://tw.fisg.com/register/trader?link_id=ct01egz0&referrer_id=v4w8jplv'
  },
  shouye:{
  url:'/'
  },
fromSuccess:'gửi thành công',
name:'Star Credits Mall',
name:'Đăng ký với tư cách là một nhà giao dịch',
text:'Không có tài khoản? Bấm Đăng ký >>',
forgot:'Quên mật khẩu?',
title:'Đăng nhập tài khoản',
email:'Hộp thư',
pwd:'Mật khẩu',
denglu:'Đăng nhập',
mima:'Quên mật khẩu',
Reset:' Đặt lại mật khẩu',
p_email:' Vui lòng nhập email',
p_pwd:' Vui lòng nhập mật khẩu',
p_new_pwd:'Vui lòng nhập mật khẩu của bạn',
z_email:' Vui lòng nhập địa chỉ email chính xác',
long:'Độ dài từ 6 đến 18 ký tự',
determine:'Mục đích',
Valid:' Đơn đặt lại mật khẩu đã được gửi. Vui lòng kiểm tra email của bạn và kiểm tra liên kết đặt lại. Liên kết có giá trị trong vòng 24 giờ!',
Help_reset:' Nhập địa chỉ email đã đăng ký của bạn và chúng tôi sẽ giúp bạn đặt lại mật khẩu.',


 },
myhead:{
loginName:'Đăng nhập',
Personalcenter:'Trung tâm cá nhân',
Placeholder:' Vui lòng nhập ...',

myhead_a:'Trang chủ',
myhead_b:'Sàn giao dịch',
myhead_c:'',
myhead_d:' Tìm hiểu đầu tư',
myhead_e:' Công ty',
myhead_f:'Interstellarfx luôn luôn Hỗ trợ bạn',
myhead_g:'Hãy là nhà giao dịch tốt nhất',
myhead_h:'Bắt đầu giao dịch',
myhead_i:'Dùng thử bản demo miễn phí',
myhead_k:'Hoàn tiền cho các giao dịch',
myhead_j:{

text:'Hoạt động marketing.',

url:'https://www.interstellarfx.net/zh/trade-cashback'

},
myhead_l:'Thị trường',
myhead_m:'Liên quan đến giao dịch ',
myhead_n:'Tìm hiểu ngoại hối',
  myhead_p:'Thông báo từ InterStellar',
  myhead_q:'Dự báo thị trường',
  
  myhead_r:'Là một sản phẩm giao dịch tài chính phức tạp, hợp đồng chênh lệch (CFD) có rủi ro thua lỗ nhanh chóng cao do tính năng đòn bẩy của nó. Hầu hết các tài khoản của nhà đầu tư bán lẻ đều ghi nhận khoản lỗ trong các hợp đồng chênh lệch. Bạn nên xem xét liệu bạn đã phát triển sự hiểu biết đầy đủ về các quy tắc hoạt động của hợp đồng chênh lệch hay chưa và liệu bạn có thể chịu rủi ro mất vốn cao hay không.',
  myhead_s:'Công ty',
  myhead_t:'Thị trường',
  myhead_u:'Trích dẫn',
  myhead_v:'Phân tích và Kiến thức',
  myhead_v_a:' Phân tích',
  myhead_v_b:' Kiến thức',
  myhead_w:'Giám sát',
  myhead_x:'Bảo hiểm',
  myhead_y:'Pháp lý',
  myhead_z:'cập nhật mới nhất trong tiếng Việt."',
  myhead_aa:'Hướng dẫn giao dịch',
  myhead_bb:'Bảng chú giải thuật ngữ giao dịch',
  myhead_cc:'Tin tức tức thời',
  myhead_dd:'Phân tích giao dịch',
  myhead_ee:'Đầu tư CỦA Forex',
  myhead_ff:'Tài trợ & Trách nhiệm xã hội',
  myhead_gg:'hội thảo trực tuyến ISG',
  myhead_hh:'ISG Phân tích',
  myhead_ii:'khóa học giao dịch',
},



Home:{
Home_a:' Trung tâm mua sắm Star Points',
Home_b:' Đổi điểm để nhận quà!',
Home_c:' Tích điểm để nhận những phần quà tuyệt vời nhất : thời trang, kỹ thuật số, thiết bị điện thông minh, xe hơi sang trọng đẳng cấp và hàng ngàn phần thưởng hấp dẫn khác ...',
Home_d:' Xem các điều khoản và quy định hoạt động được áp dụng',
Home_e:'Nhấp chuột',
Home_f:'Tại đây',
Home_g:'Những món quà hấp dẫn, đang chờ bạn',
Home_h:'Bạn muốn tìm kiếm những món quà khác?',
Home_i:' Bạn có thể giới thiệu các sản phẩm bạn muốn cho chúng tôi bất cứ lúc nào, chúng tôi sẽ bổ sung các sản phẩm tùy theo mức độ phổ biến của món quà được đề xuất ...',
Home_j:' Thương hiệu nổi bật',
Home_k:' Mô tả hàng hóa',
Home_l:'Nộp hồ sơ',
Home_m:' Điểm thưởng & Quy tắc đổi quà',
Home_n:' Cách tích điểm',
Home_o:' Bất kỳ khách hàng nào của Interstellar FX giao dịch các cặp tiền tệ, kim loại quý và các sản phẩm năng lượng trên nền tảng của chúng tôi sẽ nhận được 1 tín dụng cho mỗi giao dịch.',
Home_p:' Cách đổi quà',
Home_q:' Gửi đơn đăng ký trao đổi trong trung tâm mua sắm tích điểm (điền tên, thông tin liên hệ, số tài khoản giao dịch và các thông tin khác của bạn), nền tảng sẽ kiểm tra điểm của bạn và xem có bất kỳ giao dịch gian lận nào gần đây không và phân phối chúng sau khi vượt qua quá trình xem xét.',
Home_r:' Thời gian giao quà',
Home_s:' Quà tặng sẽ được giao trong vòng 15 ngày sau khi đơn đăng ký đổi hàng được chấp thuận, hãy lưu ý kiểm tra, bạn cũng có thể liên hệ với người quản lý dịch vụ khách hàng của chính mình để kiểm tra tiến độ giao hàng.',
Home_t:' Hướng dẫn sau bán hàng',
Home_u:' Tất cả hàng hóa được liệt kê trong trung tâm Giao dịch tích hợp đều được cung cấp bởi các nhà cung cấp bên thứ ba và không hỗ trợ việc trả lại bất hợp lý. Tuy nhiên, nếu có nhầm quà, hoặc hư hỏng trong quá trình chuyển phát nhanh, bạn có thể liên hệ với bộ phận chăm sóc khách hàng độc quyền của mình để giải quyết vấn đề.',
Home_v:' khuyến nghị phổ biến',
Home_w:'Những món quà cực hot đang chờ bạn ',
Home_x:' Chuyển đổi',
Home_y:' Điểm',
Home_z:'Chọn nhãn hiệu',
Home_aa:' Mảng sản phẩm mới',
Home_bb:' Sắp xếp điểm',
Home_cc:' Chọn nhãn hiệu',
Home_dd:'Duy nhất',
Home_ee:' Điểm đổi được',
Home_ff:' Vui lòng nhập nhãn hiệu quà tặng',
Home_gg:' Vui lòng nhập mô tả sản phẩm',
Home_hh:' Điều kiện',
Home_ii:' Thành công',
Home_zz:' Integral mall',
Home_kk:' Point Mall',
Home_search:'tìm kiếm'

},
detail:{
detail_a:' Quay lại trang chủ của trung tâm mua sắm',
detail_b:'Mô hình',
detail_c:'Con số',
detail_d:' Hướng dẫn đổi quà',
detail_e:' Quà sẽ được giao trong vòng 15 ngày sau khi đơn đổi quà được duyệt, bạn lưu ý kiểm tra lại nhé, bạn cũng có thể liên hệ với người quản lý dịch vụ khách quà tận tâm của mình để kiểm tra tiến độ giao quà.',
detail_f:' Tất cả hàng hóa được liệt kê trong trung tâm mua sắm Integrpoint đều được cung cấp bởi bên thứ ba. Vì vậy việc trả lại hàng mà không có lý do chính đáng sẽ không được hỗ trợ. Tuy nhiên, nếu có nhầm quà hoặc hư hỏng trong quá trình chuyển phát nhanh, bạn có thể liên hệ với bộ phận chăm sóc khách hàng để giải quyết vấn đề.',
detail_g:' Thông tin chi tiết sản phẩm',
detail_h:' Đề xuất phổ biến',
detail_i:'Hãy đăng nhập',
address_s:'Thêm địa chỉ nhận hàng',

},
settlement:{
settlement_a:'Thông tin đổi quà',
settlement_b:'Tên',
settlement_c:'Sự chỉ rõ',
settlement_d:'Đơn giá',
settlement_e:'Con số',
settlement_f:'Thông tin người nhận hàng',
settlement_g:'Thêm địa chỉ',
settlement_h:'Số tiền thanh toán',
settlement_i:'Điểm',
settlement_z:'Giải quyết ngay lập tức',
		settlement_l:'Vui lòng xác nhận đơn đặt hàng của bạn!',
		settlement_m:'Hàng hóa',
		settlement_n:'Thông tin sản phẩm!',
		settlement_o:'Thông tin điểm!',
		settlement_p:'Điểm tích lũy hiện tại không đủ',
settlement_k:'Cam kết thành công',
settlement_title:'Để thành công！',
settlement_tip:'Món quà được chọn của bạn sẽ được giao trong vòng 15 ngày làm việc. Bạn cũng có thể liên hệ với người quản lý dịch vụ khách hàng  để kiểm tra tiến độ giao hàng.',
settlement_button:'mục đích',
settlement_tip_error:'Phương thức nhận điểm: Giao dịch các cặp tiền tệ, kim loại quý và các sản phẩm năng lượng, nhận được 1 điểm cho mỗi lot giao dịch (các lệnh trên ba phút được coi là hợp lệ)',

},
meber:{
meber_a:'Trung tâm đổi quà',
meber_b:'Trung tâm cá nhân',
meber_c:'Đơn hàng của tôi',
meber_d:'Quản lý địa chỉ',
meber_e:'Đăng xuất',

},

address:{

address_a:'Tăng',
address_b:'Tên khách hàng',
address_c:'Số điện thoại',
address_d:'Địa chỉ nhà',
address_e:'Hoạt động',
address_f:'Chỉnh sửa',
address_g:'Xóa bỏ',
address_h:'Vui lòng chọn',
address_i:'Địa chỉ chi tiết',
address_j:'Tiết kiệm',
address_k:'Vui lòng điền tên của bạn',
address_l:'Xin vui lòng điền số điện thoại của bạn',
address_m:'Định dạng số điện thoại không chính xác',
address_n:'Xin vui lòng điền số điện thoại của bạn',
address_o:'Vui lòng nhập địa chỉ đầy đủ',
address_p:'Đã sửa đổi thành công',
address_q:'Thêm thành công',
address_r:'Xóa thành công',
address_s:'Địa chỉ nhận hàng mới',
address_t:'Bạn chưa thêm địa chỉ giao hàng',

},

order:{
order_a:'Đơn hàng của tôi',
order_b:' Ngày',
order_c:'Số đơn hàng',
order_d:'Người nhận',
order_e:' Số lượng',
order_f:'Chi tiết đơn hàng',
order_g:' Cuộc gọi',
order_h:' Địa chỉ nhà',
order_i:'Tỉnh',
order_j:'Chế độ vận chuyển',
order_k:'Hoạt động',
order_l:'Đã đóng cửa',
order_m:'Tạo',
order_n:'Trả',
order_o:'Đang hoàn lại tiền',
order_p:'Đã hoàn lại',
order_q:'Đã vận chuyển',
order_r:'Hoàn thành',
order_s:'Tích phân chi phí',

},

userinfo:{
userinfo_a:'Trung tâm cá nhân',
userinfo_b:'lần cập nhật cuối cùng',
userinfo_c:'Hộp thư người dùng',
userinfo_d:'Các khoản tín dụng có sẵn',

},

orderDetail:{
orderDetail_a:'Nộp đơn đổi quà',
orderDetail_b:'Thanh toán thành công',
orderDetail_c:'Mục ra',
orderDetail_d:'Đang chờ giao hàng',
orderDetail_e:' Hoàn thành',
orderDetail_f:'Phương thức thanh toán',
orderDetail_g:'Tên chuyển phát nhanh',
orderDetail_h:'Mã vận đơn',

},

footerbox:{
footerbox_b:'Thị trường toàn cầu ngày nay',
footerbox_c:'THỬ DEMO MIỄN PHÍ',
footerbox_d:'Dịch vụ khách hàng Châu Á Thái Bình Dương （Singapore）',
footerbox_e:'Email công ty',
footerbox_f:'support@interstellarsfx.com',
footerbox_g:'Bắt đầu',
footerbox_h:'Ngoại hối',
footerbox_i:'Kim loại',
footerbox_j:'Năng lượng',
footerbox_k:'Chỉ số',
footerbox_a:'Về giao dịch',
footerbox_l:'Tài khoản',
footerbox_m:'Các loại tài khoản',
footerbox_n:'Nạp và rút tiền',
footerbox_o:'Bắt đầu giao dịch',
footerbox_p:'Dùng thử bản demo miễn phí',
footerbox_q:'Công cụ',
footerbox_r:'MetaTrader 4',
footerbox_s:'MAM',
footerbox_t:'Đa danh nghĩa',
footerbox_u:'Lịch kinh tế',
footerbox_v:'Máy tính ngoại hối',
footerbox_w:'VPS',
footerbox_x:'Trung tâm giao dịch',
footerbox_y:'Kiến thức Giao dịch',
footerbox_z:'Tra cứu thuật ngữ',
footerbox_aa:'Giới thiệu về InterStellar',
footerbox_bb:'Tin tức',
footerbox_cc:'Tuyển dụng',
footerbox_dd:'Liên hệ chúng tôi',
footerbox_ee:'Đối tác',
footerbox_ff:'Câu hỏi thường gặp',

footerbox_mm:'Tuyên bố miễn trừ trách nhiệm: ',
footerbox_nn:`Trang web www.interstellarsfx.com được điều hành bởi First Interstellar Global Ltd, một công ty trách nhiệm hữu hạn được thành lập theo luật của Seychelles, có số đăng ký [insert Company’s registration number] và có địa chỉ văn phòng đã đăng ký tại Phòng 9, Tòa nhà Deenu, Providence , Mahe, Seychelles.First Interstellar Global Ltd được ủy quyền và quản lý bởi Cơ quan Dịch vụ Tài chính của Seychelles với giấy phép số. SD127. First Interstellar Global Ltd không nằm trong khuôn khổ quy định tài chính châu Âu. Chỉ thị về Thị trường trong Công cụ Tài chính (MiFID) II, cũng như bất kỳ quy định nào khác về dịch vụ tài chính của Châu Âu, không áp dụng cho việc cung cấp các dịch vụ tài chính của First Interstellar Global Ltd. Ngoài ra, không có quy định nào về chương trình bồi thường cho nhà đầu tư.`,
footerbox_gg:'InterstellarFX không cung cấp CFD cho cư dân của một số khu vực pháp lý nhất định, bao gồm Hoa Kỳ, Iran, Canada, Bỉ và Pháp.',
footerbox_hh:'Cảnh báo rủi ro:',
footerbox_ii:`Giao dịch ngoại hối và các sản phẩm tài chính sử dụng đòn bẩy có rủi ro cao và có thể khiến bạn mất vốn. Bạn không nên chấp nhận rủi ro thua lỗ nhiều hơn khả năng của mình và vui lòng đảm bảo rằng bạn hiểu đầy đủ những rủi ro liên quan. Giao dịch các sản phẩm có đòn bẩy cao có thể không phù hợp với tất cả các nhà đầu tư. Giao dịch các sản phẩm không sử dụng đòn bẩy như cổ phiếu cũng tiềm ẩn rủi ro vì giá trị của cổ phiếu có thể tăng hoặc giảm, nghĩa là bạn có thể nhận lại ít tiền hơn số tiền đầu tư ban đầu. Hiệu suất trong quá khứ không đảm bảo cho kết quả trong tương lai. Trước khi giao dịch, vui lòng xem xét mức độ kinh nghiệm, mục tiêu đầu tư của bạn và tìm tư vấn tài chính độc lập nếu cần. Khách hàng có trách nhiệm đảm bảo rằng các yêu cầu pháp lý của quốc gia cư trú của mình cho phép họ sử dụng các dịch vụ do thương hiệu StarWorld cung cấp.`,
footerbox_zz:'Báo cáo rủi ro',
footerbox_kk:'Chính sách bảo mật',
footerbox_ll:'Copyright © 2021 Interstellar FX. All rights reserved.',
}
}