//马来西亚
export default {
 tip:{
  fromSuccess:'Dihantar dengan berjaya'
 },
 login:{
   linkList:[{name:'Pusat Pembelian Xingguang',url:'/'},{name:'Daftar sebagai pengedar',url:'https://tw.fisg.com/register/trader?link_id=ct01egz0&referrer_id=v4w8jplv'}],
   registered:{
  text:'Awak tak ada akaun? Daftar > > Klik',
  url:'https://tw.fisg.com/register/trader?link_id=ct01egz0&referrer_id=v4w8jplv'
   },
   shouye:{
     url:'/'
   },
	forgot : 'Adakah anda lupa kata laluan anda?',
	title : 'Logmasuk akaun',
	email : 'kotak mel',
	pwd : 'Argot',
	denglu : 'Daftar masuk',
	mima : 'Lupakan kata laluan',
	Reset : 'reset kata laluan',
	p_email : 'Sila masukkan e-mel.',
	p_pwd : 'Sila masukkan kata laluan.',
	p_new_pwd : 'Sila masukkan kata laluan.',
	z_email : 'Sila masukkan alamat e-mel yang betul.',
	long : 'Panjang 6 hingga 18 aksara',
	determine : 'keputusan',
	Valid : 'Aplikasi reset kata laluan telah dihantar. Sila semak e-mel dan sahkan pautan semula. Pautan ini sah selama 24 jam!',
	Help_reset : 'Selepas memasukkan alamat emel terdaftar, anda boleh tetapkan semula kata laluan.',

  },
 myhead:{
	loginName : 'Login',
	Personalcenter : 'Pusat pribadi',
	Placeholder : 'Silakan masukkan...',
	myhead_a : 'Home',
	myhead_b : 'Penjualan',
	myhead_c : 'Platform',
	myhead_d : 'Bantuan dan Sumber',
	myhead_e : 'Tentang kita',
	myhead_f : 'Interstellarfx Bantu Anda',
	myhead_g : 'Jadilah pedagang terbaik',
	myhead_h : 'Mula Penjualan',
	myhead_i : 'Cuba demo percuma',

    myhead_j:{
		text:'Aktiviti pemasaran',
		url:'https://www.interstellarfx.net/zh/trade-cashback'
        },
		
	myhead_k : 'Kembali tunai pada transaksi',
	myhead_l : 'Pasar',
	myhead_m : 'Berkaitan perdagangan',
	myhead_n : 'Sekolah pertukaran asing',
	myhead_o:'Titik bonus Festival musim gugur tengah',
	myhead_p:'Notis InterStellar',
	myhead_q:'Ramalan Pasaran',
	
	myhead_r:'Sebagai produk perdagangan kewangan yang rumit, kontrak untuk perbezaan (CFD) mempunyai risiko tinggi kerugian pesat yang timbul daripada ciri leveragenya. Kebanyakan akaun pelabur runcit merekodkan kerugian dana dalam kontrak untuk perbezaan. Anda harus mempertimbangkan sama ada anda telah membangunkan pemahaman penuh tentang peraturan operasi kontrak untuk perbezaan dan sama ada anda boleh menanggung risiko kerugian dana yang tinggi.',
	myhead_s:'Syarikat',
	myhead_t:'Pasaran',
	myhead_u:'Main',
	myhead_v:'Analisis dan Pendidikan',
	myhead_v_a:' Analisis',
	myhead_v_b:' Pendidikan',
	myhead_w:'Penyeliaan',
	myhead_x:'Insurans',
	myhead_y:'Pematuhan Undang-undang',
	myhead_z:'Berita terakhir',
	myhead_aa:'Panduan Perdagangan',
	myhead_bb:'Glosari Terma Dagangan',
	myhead_cc:'berita masa nyata',
	myhead_dd:'Analisis Transaksi',
	myhead_ee:'Pelaburan Forex',
	myhead_ff:'Penajaan & Tanggungjawab sosial',
	myhead_gg:'ISG Webinar',
	myhead_hh:'ISG Analisis',
	myhead_ii:'kursus perdagangan',
 },
 Home:{
	Home_a : 'Mall Titik Bintang',
	Home_b : 'Titik perdagangan untuk hadiah!',
	Home_c : 'Titik perdagangan untuk hadiah yang baik, fashion digital, peralatan elektrik yang indah, kereta mewah atas dan banyak perkara baik lain untuk dibawa pulang kapan-kapan...',
	Home_d : 'Lihat terma & peraturan aktiviti berlaku',
	Home_e : 'Klik',
	Home_f : 'Di sini',
	Home_g : 'Hadiah yang baik, menunggu anda',
	Home_h : 'Tiada hadiah yang kau inginkan?',
	Home_i : 'Anda diterima untuk menyarankan produk yang anda inginkan kepada kami kapan-kapan, kami akan tambah produk menurut popularitas hadiah yang disarankan ...',
	Home_j : 'Tanda hadiah',
	Home_k : 'Huraian komoditi',
	Home_l : 'Penghantaran',
	Home_m : 'Peraturan pertukaran hadiah titik bonus',
	Home_n : 'Bagaimana mendapatkan titik',
	Home_o : 'Setiap pelanggan FX antar bintang yang bertukar pasangan wang, logam berharga dan produk tenaga di platform kita akan mendapat 1 kredit untuk setiap perdagangan.',
	Home_p : 'Bagaimana untuk bertukar hadiah',
	Home_q : 'Hantar aplikasi pertukaran di pusat mall titik (isi nama anda, maklumat kenalan, dan nombor akaun perdagangan dan maklumat lain), dan latar belakang akan memeriksa titik anda dan sama ada ada ada sebarang transaksi haram baru-baru ini, dan mengedarkannya selepas lulus ulasan.',
	Home_r : 'Masa penghantaran hadiah',
	Home_s : 'Hadiah akan dihantar dalam 15 hari selepas aplikasi pertukaran diterima, sila perhatikan bahawa semak, anda juga boleh hubungi pengurus perkhidmatan pelanggan anda sendiri untuk memeriksa kemajuan penghantaran.',
	Home_t : 'Arahan selepas menjual',
	Home_u : 'Semua barang yang disenaraikan di pusat mall integral disediakan oleh penyedia pihak ketiga, dan tiada kembalian yang tidak masuk akal disokong. Namun, jika ada hadiah yang salah, atau kerosakan dalam proses penghantaran ekspres, anda boleh hubungi perkhidmatan pelanggan eksklusif anda untuk menyelesaikan masalah.',
	Home_v : 'rekomendasi popular',
	Home_w : 'Barang-barang yang paling popular menunggu anda di sini',
	Home_x : 'Tukar',
	Home_y : 'Titik',
	Home_z : 'Pilih tanda',
	Home_aa : 'Aras produk baru',
	Home_bb : 'Isian titik',
	Home_cc : 'Pilih tanda',
	Home_dd : 'Hanya',
	Home_ee : 'Titik boleh diselamatkan',
	Home_ff : 'Sila masukkan tanda hadiah',
	Home_gg : 'Sila masukkan keterangan produk',
	Home_hh : 'Terma',
	Home_ii : 'Berjaya',
	Home_zz : 'Pusat Mudah Integral',
	Home_kk : 'Pusat Mudah Integral',
	Home_search:'Cari produk'

 },
 detail:{
	detail_a : 'Kembali ke halaman rumah pusat mall',
	detail_b : 'Model',
	detail_c : 'Nombor',
	detail_d : 'Arahan Tukar Hadiah',
	detail_e : 'Hadiah ini akan dihantar dalam 15 hari selepas persetujuan aplikasi pertukaran, sila perhatikan bahawa semak, anda juga boleh hubungi pengurus perkhidmatan pelanggan yang ditugaskan anda untuk memeriksa kemajuan penghantaran.',
	detail_f : 'Semua barang yang disenaraikan di pusat mall Integrpoint disediakan oleh penyedia pihak ketiga, dan kembalian tanpa sebab tidak disokong. Namun, jika ada hadiah atau kerosakan yang salah dalam proses penghantaran ekspres, anda boleh hubungi perkhidmatan pelanggan eksklusif anda untuk menyelesaikan masalah.',
	detail_g : 'Perincian produk',
	detail_h : 'Rekomendasi popular',
	detail_i : 'Sila log masuk',

 },
 settlement:{
    balance:'Imbangan kredit',
	settlement_a : 'Maklumat Perintah',
	settlement_b : 'Nama',
	settlement_c : 'Spesifikasi',
	settlement_d : 'Harga unit',
	settlement_e : 'Nombor',
	settlement_f : 'Maklumat penerima',
	settlement_g : 'Tambah alamat',
	settlement_h : 'Jumlah penyelesaian',
	settlement_i : 'Titik',
	settlement_z : 'Tetapkan segera',
	settlement_k : 'Commit berjaya',
	settlement_l:'Sila sahkan arahan anda!',
	settlement_m:'Produk',
	settlement_n:'Maklumat produk',
	settlement_o:'Maklumat titik!',
	settlement_p:'Titik yang diperlukan semasa tidak cukup',
	settlement_title : 'Untuk berjaya',
	settlement_tip : 'Perintah pertukaran anda akan dihantar dalam 15 hari kerja. Anda juga boleh menghubungi pengurus perkhidmatan pelanggan anda untuk memeriksa kemajuan penghantaran.',
	settlement_button : 'tentukan',
    settlement_tip_error:'Kaedah pemilihan titik: untuk perdagangan pasangan valuta, logam berharga dan produk tenaga, anda boleh mendapatkan 1 titik untuk setiap loteng perdagangan (arahan dengan kedudukan lebih dari tiga minit dianggap sah)',
 },
 meber:{
	meber_a : 'Pusat perintah',
	meber_b : 'Pusat peribadi',
	meber_c : 'Perintah saya',
	meber_d : 'Pengurusan Alamat',
	meber_e : 'Log keluar',
 }, 
 
 address:{
	address_a : 'Tambah',
	address_b : 'Nama',
	address_c : 'Panggilan',
	address_d : 'Alamat',
	address_e : 'Operasi',
	address_f : 'Menyunting',
	address_g : 'Padam',
	address_h : 'Sila pilih',
	address_i : 'Alamat terperinci',
	address_j : 'Simpan',
	address_k : 'Masukkan nama anda',
	address_l : 'Sila masukkan nombor telefon anda',
	address_m : 'Format nombor telefon tidak betul',
	address_n : 'Sila masukkan nombor telefon anda',
	address_o : 'Sila masukkan alamat penuh',
	address_p : 'Berubah berjaya',
	address_q : 'Tambah berjaya',
	address_r : 'Padam berjaya',
	address_s : 'Alamat panen baru',
	address_t : 'Anda tidak menambah alamat penghantaran',

 },
 
 order:{
	order_a : 'Perintah saya',
	order_b : 'Tarikh',
	order_c : 'Nombor perintah',
	order_d : 'Penerima',
	order_e : 'Jumlah',
	order_f : 'Perincian perintah',
	order_g : 'Panggilan',
	order_h : 'Alamat',
	order_i : 'Keadaan',
	order_j : 'Mod penghantaran',
	order_k : 'Operasi',
	order_l : 'Tutup',
	order_m : 'Cipta',
	order_n : 'Dibayar',
	order_o : 'Kembali dalam proses',
	order_p : 'Terbalik',
	order_q : 'Dihantar',
	order_r : 'Selesai',
	order_s : 'Integral kost', 
 },
 
 userinfo:{
	userinfo_a : 'Pusat peribadi',
	userinfo_b : 'masa kemaskini terakhir',
	userinfo_c : 'Kotak mel pengguna',
	userinfo_d : 'Kredit tersedia',

 },
 
 orderDetail:{
	orderDetail_a : 'Hantar tertib',
	orderDetail_b : 'Pembayaran berjaya',
	orderDetail_c : 'Item keluar',
	orderDetail_d : 'Menunggu penghantaran',
	orderDetail_e : 'Selesai',
	orderDetail_f : 'Kaedah pembayaran',
	orderDetail_g : 'Nama Penghantar',
	orderDetail_h : 'Nombor trek',

 },
 
 footerbox:{ 
	footerbox_b:'Pasar global hari ini',
	footerbox_c:'Cuba demo percuma',
	footerbox_d:'Perkhidmatan Pelanggan Asia Pasifik (Singapore)',
	footerbox_e : 'E- mel Syarikat',
	footerbox_f : 'support@interstellarsfx.com',
	footerbox_g : 'Mulakan',
	footerbox_h : 'Forex',
	footerbox_i : 'Metal',
	footerbox_j : 'Energi',
	footerbox_k : 'Indeks',
	footerbox_a : 'Tentang perdagangan',
	footerbox_l : 'Akaun',
	footerbox_m : 'Jenis Akaun',
	footerbox_n : 'Deposit dan Pengeluaran',
	footerbox_o : 'Mula Penjualan',
	footerbox_p : 'Cuba demo percuma',
	footerbox_q : 'Alat',
	footerbox_r : 'MetaTrader 4',
	footerbox_s : 'MAM',
	footerbox_t : 'Multiterminal',
	footerbox_u : 'Kalendar Ekonomik',
	footerbox_v : 'Kalkulator Forex',
	footerbox_w : 'VPS',
	footerbox_x : 'Central Trading',
	footerbox_y : 'Sekolah Perdagangan',
	footerbox_z : 'Pengumpulan Terma',
	footerbox_aa : 'Tentang Kami',
	footerbox_bb : 'Berita',
	footerbox_cc : 'Karier',
	footerbox_dd : 'Hubungi Kami',
	footerbox_ee : 'Rakan',
	footerbox_ff : 'FAQ',
	
	footerbox_mm :'Penolakan:',
	footerbox_nn :'laman web www.interstellarsfx.com beroperasi oleh First Interstellar Global Ltd., syarikat tanggungjawab terbatas yang didalam undang-undang Seychelles, dengan pejabat terdaftar di bilik 9, Bangunan Deenu, Persediaan, Mahe, Seychelles. First Interstellar Global Ltd. First Interstellar Global Ltd dibenarkan dan diawasi oleh Autoritas Perkhidmatan Kewangan Seychelles di bawah nombor pengawasan SD127. First Interstellar Global Ltd tidak termasuk dalam kerangka peraturan keuangan Eropah. Market for Financial Instruments Directive (MiFID) II dan mana-mana peraturan perkhidmatan keuangan Eropah yang lain tidak berlaku untuk perkhidmatan keuangan yang disediakan oleh First Interstellar Global Ltd., dan tiada syarat mengenai rancangan pembayaran pelabur.',
	footerbox_gg : 'InterstellarFX tidak menawarkan transaksi CFD kepada penduduk di kawasan tertentu, termasuk Amerika Syarikat, Iran, Kanada, Belgium dan Perancis.',
	footerbox_hh : 'Amaran Risiko:',
	footerbox_ii : 'Penjualan pertukaran asing dan produk keuangan yang berguna adalah risiko yang tinggi, yang boleh menyebabkan anda kehilangan utama. Risiko kehilangan yang anda lakukan tidak boleh melebihi toleransi anda. Sila pastikan anda memahami sepenuhnya risiko yang terlibat. Penjualan produk yang berguna tidak sesuai untuk semua pelabur. Penjualan produk yang tidak berguna (seperti stok) juga melibatkan risiko, kerana nilai stok boleh meningkat atau jatuh, yang bermakna dana yang anda terima mungkin lebih rendah daripada pelaburan awal anda. Performasi masa lalu tidak menjamin hasil masa depan. Sebelum perdagangan, sila pertimbangkan aras pengalaman dan tujuan pelaburan anda, dan mencari nasihat kewangan independen jika perlu. Ia adalah tanggungjawab pelanggan untuk memastikan bahawa undang-undang negara tinggal beliau membenarkan beliau untuk menggunakan perkhidmatan yang disediakan oleh StarCraft.',
	footerbox_zz : 'Pengumuman Risiko',
	footerbox_kk : 'Polisi Privasi',
    footerbox_ll:'Copyright © 2021 Interstellar FX. All rights reserved.',
 }
 
}