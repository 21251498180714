//日语
export default {
tip:{

fromSuccess:'コミット成功'

},

login:{

linkList:[{name:'星間積分モール',url:'/'},{name:'登録がトレーダー',url:'https://tw.fisg.com/register/trader?link_id=ct01egz0&referrer_id=v4w8jplv'}],

registered:{

text:'アカウントはありませんか？登録>>をクリックして',

url:'https://tw.fisg.com/register/trader?link_id=ct01egz0&referrer_id=v4w8jplv'

},

shouye:{

url:'/'

},

forgot:'パスワードを忘れた？',

title:'アカウントログイン',

メール:'メールボックス',

pwd:'パスワード',

denglu:'ログイン',

mima:'パスワードを忘れる',

Reset:'パスワードのリセット',

p_email:'メールアドレスを入力してください',

p_pwd:'パスワードを入力してください',

p_new_pwd:'新入りパスワードを入力してください',

z_email:'正しいメールアドレスを入力してください',

long:'長さは6～18文字',

determine:'確認',

Valid:'パスワードリセット申請が提出されました。メールアドレスをチェックしてリンクをリセットしてください。リンクは24時間以内に有効です！',

Help_reset:'登録した電子メールボックスを入力して,パスワードをリセットするのに役立ちます。'

},

myhead:{

loginName:'ログイン',

Personalcenter:'パーソナルセンター',

placeholder:'を入力してください...',

myhead_a:'トップページ',

myhead_b:'取引',

myhead_c:'プラットフォーム',

myhead_d:'取引学院',

myhead_e:'私たちについて',

myhead_f:'星間の助力',

myhead_g:'最適なトレーダーになる',

myhead_h:'リアルアカウントを開設する',

myhead_i:'アカウントのシミュレーションを開始する',

myhead_j:{

text:'市場活動',

url:'https://www.interstellarfx.net/zh/trade-cashback'

},

myhead_k:'取引還元',

myhead_l:'取引製品',

myhead_m:'取引関連',

myhead_n:'外国為替学堂',

myhead_o:'中秋節ポイント奨励活',

myhead_p:'インターステラーからのお知らせ',
myhead_q:'市場予測',

		myhead_r:'複雑な金融取引商品である差金取引 (CFD) には、レバレッジ機能により急速な損失が発生する高いリスクがあります。 ほとんどの個人投資家口座は、差額契約で資金損失を記録しました。 差額契約の運用ルールを十分に理解しているか、資金損失の高いリスクに耐えられるかどうかを検討する必要があります。',
		myhead_s:'会社',
		myhead_t:'市場',
		myhead_u:'見積もり',
		myhead_v:'分析と教育',
		myhead_v_a:'分析',
		myhead_v_b:'教育',
		myhead_w:'かんし',
		myhead_x:'保険',
		myhead_y:'法令順守',
		myhead_z:'最新情報',
		myhead_aa:'取引ガイド',
		myhead_bb:'取引用語集',
		myhead_cc:'リアルタイムニュース',
		myhead_dd:'トランザクション分析',
		myhead_ee:'(ウェファン)投資の',
		myhead_ff:'スポンサーシップ & 社会的責任',
		myhead_gg:'ISG ウェビナー',
		myhead_hh:'ISG 分析',
		myhead_ii:'トレーディング コース',
},

Home:{

Home_a:'星間積分モール',

Home_b:'取引でポイントを得て,ポイントを豪礼に交換する！',

Home_c:'ポイントを取引して贈り物を交換し,ファッションデジタル,精緻な電気製品,トップクラスの高級車など多くの良いものをいつでも家に持ち帰る…',

Home_d:'活動条項&条例適用の表示',

Home_e:'クリック',

Home_f:'ここ',

Home_g:'カラフルで素敵な贈り物,あなたが来るのを待っています',

Home_h:'おみやげはありませんか？',

Home_i:'ご希望の商品をいつでもお勧めください。おすすめギフトの人気度に合わせて商品を補充します…',

Home_j:'ギフトブランド:小米,ファーウェイなど ',

Home_k:'商品説明:小米電動plus 9号バランス車のように…',

Home_l:'提出',

Home_m:'ポイントプレゼント交換規則',

Home_n:'積分取得方式',

Home_o:'Interstellar FXのお客様は,当社のプラットフォームで通貨ペア,貴金属,エネルギー製品を取引しており,取引1手につき,1ポイントを獲得することができます（倉庫を3分以上持つ注文は有効とみなされます）。',

Home_p:'贈り物の両替方法',

Home_q:'ポイントモールで両替申請（名前,連絡先,および取引口座などの情報を記入しなければならない）を提出し,バックグラウンドであなたのポイントと最近の違反取引行為の有無を審査し,審査が通過した後に配送する。',

Home_r:'ギフト配送時間',

Home_s:'両替申請審査通過後15日以内にプレゼントが配送されますので,ご査収に注意してください。専属カスタマーサービスマネージャに連絡して配送の進捗状況を調べることもできます。',

Home_t:'商品販売後の注意事項',

Home_u:'ポイントモールに記載されている商品は第三者のサプライヤーが提供しており,理由なく返品することはサポートされていませんが,贈り物の発送ミス,宅配中の破損などの場合は,専属のカスタマーサービスに連絡して解決することができます',

Home_v:'人気推薦',

Home_w:'トレンド商品の大物が集まって,Interstellar FXのポイント交換に来て,最も人気のあるトレンド商品はここで待っています',

Home_x:'即時両替',

Home_y:'積分',

Home_z:'ベストブランド',

Home_aa:'新品配列',

Home_bb:'積分ソート',

Home_cc:'精選ブランド',

Home_dd:'必要',

Home_ee:'積分すれば両替できる',

Home_ff:'ギフトブランドを入力してください',

Home_gg:'商品説明を入力してください',

Home_hh:'成功',

Home_hh:'条項',

Home_ii:'成功',

Home_zz:'星間積分モール',

Home_kk:'ポイントモール',

Home_search:'商品の検索'


},

detail:{

detail_a:'ショッピングモールのトップページに戻る',

detail_b:'型番',

detail_c:'数量',

detail_d:'プレゼント交換の心得',

detail_e:'両替申請審査通過後15日以内にプレゼントが配送されますので,ご査収に注意してください。また,専属カスタマーサービスマネージャに連絡して配送の進捗状況を調べることもできます。',

detail_f:'ポイントモールに記載されている商品は第三者のサプライヤーが提供しており,理由なく返品することはサポートされていませんが,贈り物の発送ミス,宅配中の破損などの場合は,専属のカスタマーサービスに連絡して解決することができます。',

detail_g:'商品詳細',

detail_h:'人気推薦',

detail_i:'ログインしてください',

},

settlement:{

balance:'ポイント残高',

settlement_a:'注文情報',

settlement_b:'名称',

settlement_c:'仕様',

settlement_d:'単価',

settlement_e:'数量',

settlement_f:'受取人情報',

settlement_g:'アドレスを追加',

settlement_h:'買掛金',

settlement_i:'積分',

settlement_z:'今すぐ送信',

settlement_k:'コミット成功',

settlement_l:'ご注文をご確認ください！',

settlement_m:'商品',

settlement_n:'商品情報！',

settlement_o:'ポイント情報！',

settlement_p:'現在必要な積分が不足している',

settlement_title:'両替成功！',

settlement_tip:'交換注文書は15営業日以内にギフト配送を行います。また,専属カスタマーサービスマネージャに連絡して配送の進捗状況を調べることもできます。',

settlement_button:'確定',

settlement_tip_error:'ポイント取得方式:取引通貨ペア,貴金属及びエネルギー製品,取引1手につき,1ポイント（在庫3分以上の注文は有効とみなす）',

},

meber:{

meber_a:'注文センター',

meber_b:'パーソナルセンター',

meber_c:'私の注文',

meber_d:'アドレス管理',

meber_e:'ログオンを終了する',

},



address:{

address_a:'増加',

address_b:'氏名',

address_c:'電話',

address_d:'アドレス',

address_e:'操作',

address_f:'編集',

address_g:'削除',

address_h:'選択してください',

address_i:'詳細アドレス',

address_j:'保存',

address_k:'名前を入力してください',

address_l:'携帯番号を入力してください',

address_m:'携帯番号のフォーマットが正しくない',

address_n:'携帯番号を入力してください',

address_o:'詳細アドレスを入力してください',

address_p:'修正成功',

address_q:'新規成功',

address_r:'削除に成功しました',

address_s:'新規出荷先住所',

address_t:'出荷先住所を追加していません',

},



order:{

order_a:'私の注文',

order_b:'日付',

order_c:'注文番号',

order_d:'受信者',

order_e:'金額',

order_f:'注文詳細',

order_g:'電話',

order_h:'アドレス',

order_i:'状態',

order_j:'配送方式',

order_k:'操作',

order_l:'閉じました',

order_m:'作成済み',

order_n:'支払済み',

order_o:'返金中',

order_p:'返金済み',

order_q:'出荷済み',

order_r:'完了しました',

order_s:'ポイントがかかる',



},



userinfo:{

userinfo_a:'パーソナルセンター',

userinfo_b:'最終更新時間',

userinfo_c:'ユーザーメールボックス',

userinfo_d:'利用可能積分',

},



orderDetail:{

orderDetail_a:'発注書の提出',

orderDetail_b:'支払い成功',

orderDetail_c:'商品出庫',

orderDetail_d:'出荷待ち',

orderDetail_e:'完了',

orderDetail_f:'支払い方法',

orderDetail_g:'配送方式',

orderDetail_h:'宅配便番号'

},



footerbox:{

footerbox_b:'今日のグローバル市場',

footerbox_c:'無料のデモを試みる',

footerbox_d:'アジア太平洋地域カスタマーサービス（シンガポール）',

footerbox_e:'会社メールボックス',

footerbox_f:'support@interstellarsfx.com',



footerbox_g:'取引開始',

footerbox_h:'外貨',

footerbox_i:'金属',

footerbox_j:'エネルギー',

footerbox_k:'指数',

footerbox_a:'取引について',



footerbox_l:'口座',

footerbox_m:'口座タイプ',

footerbox_n:'預金と引き出し',

footerbox_o:'リアルアカウント開設',

footerbox_p:'シミュレーションアカウントを開設する',



footerbox_q:'取引ツール',

footerbox_r:'MetaTrader 4',

footerbox_s:'MAM',

footerbox_t:'Multiterminal',

footerbox_u:'財経カレンダー',

footerbox_v:'外貨計算機',

footerbox_w:'VPS',

footerbox_x:'Trading Central',



footerbox_y:'取引学院',

footerbox_z:'用語集',



footerbox_aa:'私たちについて',

footerbox_bb:'ニュース',

footerbox_cc:'招賢納士',

footerbox_dd:'連絡して',

footerbox_ee:'パートナー',

footerbox_ff:'よくある質問',


footerbox_mm:'免責事項：',

footerbox_nn:` Webサイトwww.interstellarsfx.comはFirst Interstellar Global Ltdによって運営されている。同社はセーシェル法に基づいて登録されて設立された有限責任会社で、登録オフィス住所はRoom 9、Deenu's Building、Providence、Mahe、Seychelles.First Interstellar Global Ltd。First Interstellar Global Ltdは、セーシェル金融サービス管理局によって認可され、規制番号はSD 127です。First Interstellar Global Ltdは欧州金融規制の枠組みに属しておらず、金融ツール市場指令（MiFID）IIおよびその他の欧州金融サービス法規はFirst Interstellar Global Ltd.が提供する金融サービスには適用されず、投資家補償計画の規定はない。`,

footerbox_gg:'InterstellarFXは、米国、イラン、カナダ、ベルギー、フランスを含む一部の管轄地域の住民に差額契約取引を提供していません。',

footerbox_hh:'リスク警告:',

footerbox_ii:`外国為替やレバレッジ金融銘柄の取引には高いリスクがあり、元金を失う可能性があります。あなたが負担する損失リスクは、あなたの負担能力を超えてはいけません。そのリスクを完全に理解していることを確認してください。レバレッジ製品の取引はすべての投資家に適していない。株式などの非レバレッジ製品の取引にはリスクも関係しています。株式の価値は上昇したり下落したりすることができるため、これはあなたが回収した資金があなたの最初の投資を下回る可能性があることを意味します。過去の表現は未来の結果を保証するものではない。取引の前に、経験レベル、投資目標を考慮してください。必要に応じて独立した財務上のアドバイスを求めてください。お客様は、星間ブランドが提供するサービスを使用することを許可することを求めている居住国の法律を確保する責任があります。`,

footerbox_zz:'リスク宣言',

footerbox_kk:'プライバシーポリシー',

footerbox_ll:'Copyright © 2021 Interstellar FX. All rights reserved.',

}
}